import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Card,
  DatePicker,
  message,
  Row,
  Col,
} from "antd";
import locale from "antd/es/date-picker/locale/ar_EG";
import Icon, {
  MailOutlined,
  UserOutlined,
  LockOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import CityIcon from "../../utils/Images/CityIcon";
import config from "../../configs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
const { Option } = Select;
const dateFormat = "YYYY/MM/DD";

const Register = () => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  polyfillCountryFlagEmojis();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_countries_api, {
        headers: {
          Accept: "application/json",
          "Accept-Language": "ar",
        },
      })
      .then((response) => setCountries(response.data.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = (values) => {
    setIsLoading(true);
    axios
      .post(
        process.env.REACT_APP_register_api,
        {
          user_firstname: `${values.first_name} ${values.last_name}`,
          user_countryiso: values.country,
          user_email: values.email,
          user_password: values.password,
          user_password_confirm: values.password_confirmation,
          user_gender: values.gender,
          user_school_grade: values.grade,
          user_city: values.city,
          user_birth_date: values.date
            ? dayjs(values.date).format("YYYY/MM/DD")
            : null,
        },
        {
          headers: {
            Accept: "application/json",
            "Accept-Language": "ar",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          messageApi.success("تم إنشاء حسابك بنجاح. سجل دخولك و ابدأ الأن");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else if (response.data.message === "البريد الإلكتروني مسجل مسبقا") {
          messageApi.error(response.data.message);
        } else {
          messageApi.info("من فضلك تأكد من إدخال جميع البيانات بشكل صحيح");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="reg__wrapper">
      {contextHolder}
      <img src={config.APP_LOGO_PATH} alt="LOGO" className="reg__logo" />
      <Card title="انشئ حسابك" className="reg__card">
        <Form form={form} name="register" layout="vertical" onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="first_name"
                label="الإسم الأول"
                rules={[
                  {
                    required: true,
                    message: "من فضلك أدخل الإسم الأول !",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="أدخل الإسم الأول"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="last_name"
                label="الإسم الأخير"
                rules={[
                  {
                    required: true,
                    message: "من فضلك أدخل الإسم الأخير !",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="أدخل الإسم الأخير"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="email"
                label="البريد الالكتروني"
                rules={[
                  {
                    type: "email",
                    message: "البريد الالكتروني غير صالح !",
                  },
                  {
                    required: true,
                    message: "من فضلك أدخل بريدك الالكتروني !",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<MailOutlined />}
                  placeholder="أدخل بريدك الإلكتروني"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="date"
                label="تاريخ الميلاد"
                rules={[
                  {
                    required: true,
                    message: "من فضلك اختر تاريخ الميلاد !",
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  size="large"
                  style={{ width: "100%" }}
                  format={dateFormat}
                  placeholder="يوم / شهر / سنة"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="password"
                label="كلمة المرور"
                rules={[
                  {
                    required: true,
                    message: "من فضلك أدخل كلمة مرور !",
                  },
                  {
                    pattern: /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/,
                    message:
                      "يجب أن تتكون كلمة المرور من 8 إلى 16 حرف محتويةً على الأقل على حرف واحد من A-Z أو a-z و كذلك تحتوي على واحد من الرموز التاليه على الأقل !-@-#-$-%-^-&-* !",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="أدخل كلمة المرور"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="password_confirmation"
                label="تأكيد كلمة المرور"
                rules={[
                  {
                    required: true,
                    message: "من فضلك أدخل كلمة المرور مرة أخرى !",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== getFieldValue("password")) {
                        return Promise.reject(
                          new Error("يجب أن تتطابق كلمتي المرور !")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="أدخل كلمة المرور مرة أخرى"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="gender"
                label="النوع"
                rules={[
                  {
                    required: true,
                    message: "من فضلك اختر النوع !",
                  },
                ]}
              >
                <Select placeholder="اختر النوع" size="large" allowClear>
                  <Option value="ذكر" key={1}>
                    <ManOutlined style={{ marginLeft: "5px" }} />
                    ذكر
                  </Option>
                  <Option value="أنثى" key={2}>
                    <WomanOutlined style={{ marginLeft: "5px" }} />
                    أنثى
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="grade"
                label="الصف الدراسي"
                rules={[
                  {
                    required: true,
                    message: "من فضلك اختر الصف الدراسي !",
                  },
                ]}
              >
                <Select
                  placeholder="اختر الفصل الدراسي"
                  size="large"
                  allowClear
                >
                  <Option value="الصف الرابع" key={1}>
                    الصف الرابع
                  </Option>
                  <Option value="الصف الخامس" key={2}>
                    الصف الخامس
                  </Option>
                  <Option value="الصف السادس" key={3}>
                    الصف السادس
                  </Option>
                  <Option value="الصف السابع" key={4}>
                    الصف السابع
                  </Option>
                  <Option value="الصف الثامن" key={5}>
                    الصف الثامن
                  </Option>
                  <Option value="الصف التاسع" key={6}>
                    الصف التاسع
                  </Option>
                  <Option value="الصف العاشر" key={7}>
                    الصف العاشر
                  </Option>
                  <Option value="الصف الحادي عشر" key={8}>
                    الصف الحادي عشر
                  </Option>
                  <Option value="الصف الثاني عشر" key={9}>
                    الصف الثاني عشر
                  </Option>
                  <Option value="الجامعة" key={10}>
                    الجامعة
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="country"
                label="الدولة"
                rules={[
                  {
                    required: true,
                    message: "من فضلك اختر الدولة !",
                  },
                ]}
              >
                <Select placeholder="اختر الدولة" size="large" allowClear>
                  {countries.map((country) => {
                    return (
                      <Option value={country?.iso_code} key={country?.id}>
                        <span
                          style={{ marginInlineEnd: "5px" }}
                          className="flag-font"
                        >
                          {country?.flag}
                        </span>
                        {country?.name_ar}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item name="city" label="المدينة">
                <Input
                  size="large"
                  prefix={<Icon component={CityIcon} />}
                  placeholder="أدخل اسم المدينة"
                />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form.Item style={{ width: "100%", margin: "5px 0" }}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={isLoading}
              >
                تسجيل
              </Button>
            </Form.Item>
            <Link to="/login">لدي حساب بالفعل !</Link>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default Register;
