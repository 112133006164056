import { Layout } from "antd";
import HeaderLayout from "../HeaderLayout";
import FooterSection from "../FooterSection";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import { useLocation } from "react-router-dom";
import BackToHomeButton from "../../components/BackToHomeButton/BackToHomeButton";

const MainLayout = ({ children, theme }) => {
  const { pathname } = useLocation();

  return (
    <Layout
      className={
        theme === "light" ? "main__layout__light" : "main__layout__dark"
      }
    >
      <HeaderLayout
        isTestPage={
          pathname === "/stages/1" ||
          pathname === "/stages/2" ||
          pathname === "/stages/1/questions/1" ||
          pathname === "/stages/1/questions/2" ||
          pathname === "/stages/1/questions/3" ||
          pathname === "/stages/2/questions/4" ||
          pathname === "/stages/2/questions/5" ||
          pathname === "/stages/2/questions/6"
        }
      />
      {children}
      <FooterSection
        isTestPage={
          pathname === "/stages/1" ||
          pathname === "/stages/2" ||
          pathname === "/stages/1/questions/1" ||
          pathname === "/stages/1/questions/2" ||
          pathname === "/stages/1/questions/3" ||
          pathname === "/stages/2/questions/4" ||
          pathname === "/stages/2/questions/5" ||
          pathname === "/stages/2/questions/6"
        }
      />
      {pathname === "/stages/1" ||
      pathname === "/stages/2" ||
      pathname === "/stages/1/questions/1" ||
      pathname === "/stages/1/questions/2" ||
      pathname === "/stages/1/questions/3" ||
      pathname === "/stages/2/questions/4" ||
      pathname === "/stages/2/questions/5" ||
      pathname === "/stages/2/questions/6" ? (
        <BackToHomeButton />
      ) : (
        <LogoutButton />
      )}
    </Layout>
  );
};

export default MainLayout;
