import { useState, createContext, useEffect } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import arEG from "antd/locale/ar_EG";
import Login from "../../pages/Login";
import Register from "../../pages/Register";
import Home from "../../pages/Home";
import Error from "../../pages/Error";
import Stages from "../../pages/Stages";
import PrivateRoutes from "../../utils/PrivateRoutes";
import Tests from "../../pages/Tests";
import FAQ from "../../pages/FAQ";
import axios from "axios";
import Questions from "../../pages/Questions";
import Confirmation from "../../pages/Confirmation";
import Profile from "../../pages/Profile";
import AboutUs from "../../pages/AboutUs";
import Terms from "../../pages/Terms";
import { getToken } from "../../utils/getToken";
import AdminLogin from "../../pages/AdminLogin";
import AdminTests from "../../pages/AdminTests";
import AdminPrivateRoutes from "../../utils/AdminPrivateRoutes";
import TestPrivateRoutes from "../../utils/TestPrivateRoutes";
import { CookiesProvider } from "react-cookie";

function App() {
  const [token, setToken] = useState("" || getToken());
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userGender, setUserGender] = useState("");

  useEffect(() => {
    token &&
      axios
        .get(process.env.REACT_APP_user_api, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Accept-Language": "ar",
          },
        })
        .then((response) => {
          setUserName(response.data.data.user_firstname);
          setUserId(response.data.data.id);
          setUserEmail(response.data.data.user_email);
          setUserGender(response.data.data.user_gender);
        })
        .catch((err) => {
          console.log(err);
        });
    // const handleContextmenu = (e) => {
    //   e.preventDefault();
    // };
    // const handleKeydown = (e) => {
    //   // Prevent F12 (Inspect)
    //   if (e.key === "F12") {
    //     e.preventDefault();
    //   }
    //   // Prevent Ctrl+Shift+I (Inspect)
    //   if (e.ctrlKey && e.shiftKey && e.key === "I") {
    //     e.preventDefault();
    //   }
    //   // Prevent Ctrl+U (View Source)
    //   if (e.ctrlKey && e.key === "u") {
    //     e.preventDefault();
    //   }
    //   // Prevent Ctrl+S (Save Page)
    //   if (e.ctrlKey && e.key === "s") {
    //     e.preventDefault();
    //   }
    // };
    // // Disable right-click
    // document.addEventListener("contextmenu", handleContextmenu);
    // // Disable specific key combinations (F12, Ctrl+Shift+I, Ctrl+U, Ctrl+S)
    // document.addEventListener("keydown", handleKeydown);

    // return function cleanup() {
    //   document.removeEventListener("contextmenu", handleContextmenu);
    //   document.removeEventListener("keydown", handleKeydown);
    // };
  }, [token]);

  return (
    <ConfigProvider
      locale={arEG}
      direction="rtl"
      theme={{
        token: {
          colorPrimary: "#6A5AE0",
          borderRadius: "20px",
          fontFamily: "DIN Next LT Arabic",
        },
      }}
    >
      <BrowserRouter>
        <UsernameContext.Provider value={userName}>
          <UserEmailContext.Provider value={userEmail}>
            <GenderContext.Provider value={userGender}>
              <CookiesProvider>
                <Routes>
                  <Route
                    path="/login"
                    element={<Login setToken={setToken} />}
                  />
                  <Route path="/register" element={<Register />} />
                  <Route element={<PrivateRoutes token={token} />}>
                    <Route path="/" element={<Home />} />
                    <Route element={<TestPrivateRoutes />}>
                      <Route path="/stages/:stageId" element={<Stages />} />
                      <Route
                        path="/stages/:stageId/questions/:questionId"
                        element={<Questions />}
                      />
                    </Route>
                    <Route path="/confirmation" element={<Confirmation />} />
                    <Route path="/tests" element={<Tests userId={userId} />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/terms-of-use" element={<Terms />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="*" element={<Error />} />
                  </Route>
                  <Route
                    path="/admin/login"
                    element={<AdminLogin setToken={setToken} />}
                  />
                  <Route element={<AdminPrivateRoutes token={token} />}>
                    <Route path="/admin/tests" element={<AdminTests />} />
                  </Route>
                </Routes>
              </CookiesProvider>
            </GenderContext.Provider>
          </UserEmailContext.Provider>
        </UsernameContext.Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
export const UsernameContext = createContext();
export const UserEmailContext = createContext();
export const GenderContext = createContext();
