import React, {
  useState,
  useEffect,
  // useRef
} from "react";
import { Layout, Button, Input, message, Card, Typography } from "antd";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate, useLocation } from "react-router-dom";
// import { handleLogOut } from "../../utils/handleLogOut";
// import { useCookies } from "react-cookie";
import MainLayout from "../../layout/MainLayout";
const { TextArea } = Input;
const { Content } = Layout;

const Question = ({
  title,
  part,
  question,
  qusestion_info,
  question_button,
  question_img,
  next_page,
  question_id,
}) => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [answer, setAnswer] = useState(sessionStorage.getItem("answer"));
  const [questionTime, setQuestionTime] = useState(180);
  const [messageApi, contextHolder] = message.useMessage();
  const { pathname } = useLocation();
  // const intervalRef = useRef(null);
  // const [, , removePaymentCookie] = useCookies(["p_completed"]);

  useEffect(() => {
    if (
      pathname === "/stages/1/questions/1" ||
      pathname === "/stages/1/questions/2" ||
      pathname === "/stages/1/questions/3" ||
      pathname === "/stages/2/questions/4" ||
      pathname === "/stages/2/questions/5" ||
      pathname === "/stages/2/questions/6"
    ) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
      window.addEventListener("beforeunload", function () {
        sessionStorage.setItem("timer", questionTime);
        sessionStorage.setItem("answer", answer ?? "");
      });
    }

    // const devtools = {
    //   isOpen: false,
    //   orientation: undefined,
    // };
    // const threshold = 160;
    // const emitEvent = (isOpen, orientation) => {
    //   window.dispatchEvent(
    //     new CustomEvent("devtoolschange", {
    //       detail: {
    //         isOpen,
    //         orientation,
    //       },
    //     })
    //   );
    // };
    // intervalRef.current = setInterval(() => {
    //   const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    //   const heightThreshold =
    //     window.outerHeight - window.innerHeight > threshold;
    //   const orientation = widthThreshold ? "vertical" : "horizontal";
    //   if (
    //     !(heightThreshold && widthThreshold) &&
    //     ((window.Firebug &&
    //       window.Firebug.chrome &&
    //       window.Firebug.chrome.isInitialized) ||
    //       widthThreshold ||
    //       heightThreshold)
    //   ) {
    //     if (!devtools.isOpen || devtools.orientation !== orientation) {
    //       emitEvent(true, orientation);
    //     }
    //     devtools.isOpen = true;
    //     devtools.orientation = orientation;
    //   } else {
    //     if (devtools.isOpen) {
    //       emitEvent(false, undefined);
    //     }
    //     devtools.isOpen = false;
    //     devtools.orientation = undefined;
    //   }
    // }, 500);

    // const handleDevtoolsChange = (e) => {
    //   if (e.detail.isOpen) {
    //     handleLogOut(messageApi, navigate, removePaymentCookie);
    //   }
    // };

    // window.addEventListener("devtoolschange", handleDevtoolsChange);

    // // Disable copying URL from iframe by adding sandbox restrictions
    // document.querySelectorAll("iframe").forEach(function (iframe) {
    //   iframe.setAttribute(
    //     "sandbox",
    //     "allow-scripts allow-same-origin allow-presentation"
    //   );
    // });

    // return function cleanup() {
    //   clearInterval(intervalRef.current);
    //   window.removeEventListener("devtoolschange", handleDevtoolsChange);
    // };
  }, [
    pathname,
    questionTime,
    answer,
    messageApi,
    // navigate,
    // removePaymentCookie,
  ]);

  const handleInput = (e) => setAnswer(e.target.value);

  const handleComplete = () => {
    setIsDisabled(true);
    messageApi.info("انتهى الوقت! يرجى الإنتقال للسؤال التالي");
  };

  const handleSubmit = () => {
    window.scrollTo(0, 250);
    sessionStorage.setItem(`q${question_id}_answer`, answer ?? "");
    sessionStorage.setItem(`q${question_id}_timer`, questionTime);
    setTimeout(() => {
      navigate(`/${next_page}`);
    }, 100);
    setAnswer("");
    sessionStorage.setItem(`answer`, "");
    setTimerKey((prevKey) => prevKey + 1);
    sessionStorage.setItem(`timer`, 180);
    setIsDisabled(false);
  };

  const renderTimer = ({ remainingTime, color }) => {
    if (remainingTime === 0) {
      return <div className="question__counter">انتهى الوقت...</div>;
    }

    return (
      <div className="question__counter">
        <div style={{ color: "#aaa", fontSize: "16px" }}>متبقي</div>
        <div style={{ fontSize: "40px", color: color }}>{remainingTime}</div>
        <div style={{ color: "#aaa", fontSize: "16px" }}>
          {remainingTime > 10 || remainingTime === 1 ? "ثانيه" : "ثواني"}
        </div>
      </div>
    );
  };

  return (
    <MainLayout theme="dark">
      {contextHolder}
      <Content className="question__content__wrapper">
        <Typography.Title
          style={{ color: "white", textAlign: "center" }}
          level={2}
        >
          {title}
        </Typography.Title>
        <Card style={{ width: "92vw" }}>
          <div className="question__header">
            <div>
              <Typography.Title style={{ color: "#858494" }} level={4}>
                {part}
              </Typography.Title>
              <Typography.Title level={3}>{question}</Typography.Title>
            </div>
            <CountdownCircleTimer
              key={timerKey}
              size={160}
              isPlaying
              duration={180}
              initialRemainingTime={Number(sessionStorage.getItem("timer"))}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[180, 90, 20, 0]}
              onComplete={handleComplete}
              children={renderTimer}
              onUpdate={(remainingTime) => setQuestionTime(remainingTime)}
            />
          </div>
          <div className="question_image">
            {question_img.map((image) => (
              <img key={image} src={image} alt="question_image" />
            ))}
          </div>
          <TextArea
            showCount
            key={question_id}
            disabled={isDisabled}
            rows={6}
            style={{
              marginBottom: "1rem",
              resize: "none",
            }}
            placeholder="اكتب كل استعمال في سطر مستقل"
            value={answer}
            onChange={handleInput}
          />
          <Typography.Title style={{ color: "#858494" }} level={4}>
            {qusestion_info}
          </Typography.Title>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              size="large"
              className="question__submit__button"
              onClick={handleSubmit}
            >
              {question_button}
            </Button>
          </div>
        </Card>
      </Content>
    </MainLayout>
  );
};

export default Question;
