import { useState } from "react";
import { Button, Checkbox, Form, Input, Card } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const LoginCard = ({ messageApi, setToken, isAdmin }) => {
  const expires = new Date(Date.now() + 86400 * 1000).toUTCString();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const saveTokenIntoStorage = (session_token, remember) => {
    if (remember) {
      document.cookie =
        "session_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      sessionStorage.removeItem("session_token");
      document.cookie = `session_token=${session_token}; expires=${expires};path=/;`;
    } else {
      sessionStorage.removeItem("session_token");
      document.cookie =
        "session_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      sessionStorage.setItem("session_token", session_token);
    }
  };

  const onFinish = (values) => {
    setIsLoading(true);
    axios
      .post(
        process.env.REACT_APP_login_api,
        {
          user_email: values.username,
          user_password: values.password,
        },
        {
          headers: {
            Accept: "application/json",
            "Accept-Language": "ar",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          messageApi.success("تم تسجيل الدخول بنجاح");
          saveTokenIntoStorage(
            response.data.data.token,
            isAdmin ? false : values.remember
          );
          sessionStorage.setItem("isAdmin", isAdmin);
          setToken(response.data.data.token);
          setTimeout(() => {
            navigate(isAdmin ? "/admin/tests" : "/");
          }, 1500);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err?.response?.data?.data);
        err?.response?.data?.data &&
          messageApi.warning(err?.response?.data?.data);
        sessionStorage.removeItem("session_token");
        document.cookie =
          "session_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        setIsLoading(false);
      });
  };

  return (
    <Card
      title={
        isAdmin ? (
          <div>
            تسجيل الدخول <span style={{ color: "#8b0000" }}>كمسؤول</span>
          </div>
        ) : (
          "تسجيل الدخول كطالب"
        )
      }
      className="login__card"
    >
      <Form
        name="login_form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message: "البريد الالكتروني غير صالح !",
            },
            {
              required: true,
              message: "من فضلك أدخل بريد إلكتروني صحيح !",
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
            placeholder="أدخل بريدك الإلكتروني"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "من فضلك أدخل كلمة المرور بشكل صحيح !",
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            type="password"
            placeholder="أدخل كلمة المرور"
          />
        </Form.Item>

        {isAdmin ? (
          <Link
            to="/register"
            style={{ marginBottom: "10px", display: "inline-block" }}
          >
            ليس لديك حساب !
          </Link>
        ) : (
          <div className="login__props">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>تذكرني</Checkbox>
            </Form.Item>
            <Link to="/register">ليس لديك حساب !</Link>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Form.Item style={{ width: "100%", margin: "5px 0" }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={isLoading}
            >
              تسجيل الدخول
            </Button>
          </Form.Item>
          <Link to={isAdmin ? "/login" : "/admin/login"}>
            {isAdmin ? "هل أنت طالب؟" : "هل أنت مسؤول؟"}
          </Link>
        </div>
      </Form>
    </Card>
  );
};

export default LoginCard;
